@if (tagline$ | async; as tagline) {
  <tp-p-with-highlights class="tp-setup" level="p2" [value]="tagline"/>
}

<div class="setup-container">
  <div class="form-container">
    <h1 class="box-title">Create Profile</h1>
    <tp-form [formGroup]="form" [isSubmitting$]="isSubmitting$" (submit)="onSubmit()">
      <tp-form-input [form]="form" type="text" label="First Name" field="firstName" autocomplete="given-name"/>
      <tp-form-input [form]="form" type="text" label="Last Name" field="lastName" autocomplete="family-name"/>
      <tp-form-input [form]="form" type="text" label="User ID" field="id" autocomplete="username"/>
      <tp-form-actions
        [isSubmitting]="isSubmitting$ | async" submitText="Continue" [submitDisabled]="!form.dirty"
        buttonIdPrefix="createProfile"
      />
    </tp-form>
  </div>
</div>
