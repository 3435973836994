/*
 * This unpublished material is proprietary to Vaticle.
 * All rights reserved. The methods and
 * techniques described herein are considered trade secrets
 * and/or confidential. Reproduction or distribution, in whole
 * or in part, is forbidden except by express written permission
 * of Vaticle.
 */

import { Component, EventEmitter, Input, Output } from "@angular/core";
import { ApplicationState } from "../../../service/application-state.service";
import { RouterLink } from "@angular/router";
import { map } from "rxjs";
import { AsyncPipe } from "@angular/common";
import { MatButtonModule } from "@angular/material/button";
import { UserAvatarComponent } from "../../user/avatar/user-avatar.component";
import { IdentityAuthService } from "../../../service/authentication/identity-auth.service";

@Component({
    selector: "tp-topbar",
    templateUrl: "./topbar.component.html",
    styleUrls: ["./topbar.component.scss"],
    standalone: true,
    imports: [RouterLink, AsyncPipe, MatButtonModule, UserAvatarComponent],
})
export class TopbarComponent {
    @Input() avatarSectionHidden = false;
    @Output() avatarClick = new EventEmitter();
    readonly userId$ = this.app.currentUser$.pipe(map((user) => user?.id));
    readonly userEmail$ = this.identityAuth.currentUserEmail$;

    constructor(private app: ApplicationState, private identityAuth: IdentityAuthService) {}
}
