/*
 * This unpublished material is proprietary to Vaticle.
 * All rights reserved. The methods and
 * techniques described herein are considered trade secrets
 * and/or confidential. Reproduction or distribution, in whole
 * or in part, is forbidden except by express written permission
 * of Vaticle.
 */

import { Component, ViewChild } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { first } from "rxjs";
import { Organization } from "../../../concept/organization";
import { ApplicationState } from "../../../service/application-state.service";
import { AsyncPipe } from "@angular/common";
import { OrgCreationDialogComponent } from "../../org/create/org-creation-dialog.component";
import { SidebarExternalLinksComponent } from "./external-links/sidebar-external-links.component";
import { SidebarLinkComponent } from "./link/sidebar-link.component";
import { MatDialog } from "@angular/material/dialog";
import { MatSelect, MatSelectChange, MatSelectModule } from "@angular/material/select";
import { MatDividerModule } from "@angular/material/divider";
import { ModalComponent } from "typedb-platform-framework";

@Component({
    selector: "tp-sidebar",
    templateUrl: "./sidebar.component.html",
    styleUrls: ["./sidebar.component.scss"],
    standalone: true,
    imports: [SidebarLinkComponent, ModalComponent, AsyncPipe, SidebarExternalLinksComponent, MatSelectModule, MatDividerModule],
})
export class SidebarComponent {
    @ViewChild(MatSelect) orgSelector!: MatSelect;
    private _org = this.app.requireCurrentOrg();

    constructor(public app: ApplicationState, private route: ActivatedRoute, private dialog: MatDialog) {}

    get org() {
        return this.orgs?.find(x => x.uuid === this._org.uuid) || this._org;
    }

    get orgs(): Organization[] | null {
        return this.app.currentUserOrgs$.value;
    }

    onOrgChange(change: MatSelectChange) {
        const org = change.value as Organization;
        if (org.uuid !== this.org.uuid) {
            this.navigateToNewOrg(org.id);
        }
    }

    navigateToNewOrg(orgId: string) {
        this.route.data.pipe(first()).subscribe((data) => {
            const domain = data["domain"] as string | undefined;
            if (!domain || domain === "settings") window.location.href = `${window.location.origin}/org/${orgId}`;
            else window.location.href = `${window.location.origin}/org/${orgId}/${data["domain"]}`;
        });
    }

    onCreateOrgClick() {
        this.orgSelector.close();
        this.dialog.open(OrgCreationDialogComponent);
    }
}
