/*
 * This unpublished material is proprietary to Vaticle.
 * All rights reserved. The methods and
 * techniques described herein are considered trade secrets
 * and/or confidential. Reproduction or distribution, in whole
 * or in part, is forbidden except by express written permission
 * of Vaticle.
 */

import { AsyncPipe } from "@angular/common";
import { Component, DestroyRef, OnDestroy, OnInit } from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { ActivatedRoute, Router, RouterLink } from "@angular/router";
import { BehaviorSubject, catchError, combineLatest, distinctUntilChanged, filter, first, interval, map, Observable, of, shareReplay, startWith, Subject, switchMap, tap } from "rxjs";
import {
    isInDestroyableState,
    isInResumableState,
    isInSuspendableState,
    Cluster,
    ClusterStatus,
    Server,
    isInConnectableState
} from "../../../concept/cluster";
import { hasAdminAccess, hasWriteAccess } from "../../../concept/iam";
import { LOADING } from "../../../framework/strings";
import { CONNECT, DIALOG, SETUP, TRUE } from "../../../framework/url-params";
import { clustersPath, serverDetailsPath } from "../../../routing/resource-paths";
import { ClusterApi } from "../../../service/cluster/cluster-api.service";
import {
    ButtonComponent, BreadcrumbComponent, PropertiesTableComponent, ModalComponent, DeleteResourceSectionComponent,
    ConfirmationModalComponent, LinkPanelsComponent, LinkPanelComponent, DatetimePipe, SpinnerComponent,
    StrongConfirmationModalComponent, StrongConfirmationModalData, ConfirmationModalData, PropertiesTableRowComponent
} from "typedb-platform-framework";
import { DialogResult } from "../../../service/dialog.service";

import { ProjectApi } from "../../../service/project/project-api.service";
import { ApplicationState } from "../../../service/application-state.service";
import { ResourceService } from "../../../service/resource.service";
import { SnackbarService } from "../../../service/snackbar.service";
import { isBlank } from "../../../util";
import { ProjectDetailsPathPipe } from "../../project/project-details-link.pipe";
import { ResourceAvailability, PageScaffoldComponent } from "../../scaffold/page/page-scaffold.component";
import { ClusterStatusPipe } from "../cluster-status.pipe";
import { MachineTypePipe } from "../machine-type.pipe";
import { RegionPipe } from "../region.pipe";
import { StorageSizePipe } from "../storage-size.pipe";
import { StorageTypePipe } from "../storage-type.pipe";
import { ClusterUpdateDialogComponent, ClusterEditDialogData } from "../update/cluster-update-dialog.component";
import { ServerStatusPipe } from "../server-status.pipe";
import { MatTableModule } from "@angular/material/table";
import { MatDialog } from "@angular/material/dialog";
import { StatusIcon, TableCopyableTextCellComponent, TableSelectionCellComponent, TableSelectionHeaderCellComponent, TableStatusCellComponent } from "../../../framework/table";
import { MatTooltipModule } from "@angular/material/tooltip";
import { ClusterConnectDialogComponent } from "./connect/cluster-connect-dialog.component";
import { SuspendResumeModalComponent, SuspendResumeModalData } from "../suspend-resume/suspend-resume-modal.component";

@Component({
    selector: "tp-cluster-details-page",
    templateUrl: "./cluster-details-page.component.html",
    styleUrls: ["./cluster-details-page.component.scss"],
    standalone: true,
    imports: [
        PageScaffoldComponent, BreadcrumbComponent, ButtonComponent, PropertiesTableComponent, ModalComponent,
        PropertiesTableRowComponent, DeleteResourceSectionComponent, AsyncPipe, ConfirmationModalComponent, RouterLink,
        LinkPanelsComponent, LinkPanelComponent, DatetimePipe, TableSelectionCellComponent, TableStatusCellComponent,
        TableSelectionHeaderCellComponent, TableCopyableTextCellComponent, ServerStatusPipe, MatTableModule,
        SpinnerComponent, PropertiesTableComponent, ProjectDetailsPathPipe, RegionPipe, MachineTypePipe,
        StorageSizePipe, StorageTypePipe, ClusterStatusPipe, MatTooltipModule,
    ],
})
export class ClusterDetailsPageComponent implements OnInit, OnDestroy {
    readonly cluster$ = new BehaviorSubject<Cluster | null>(null);
    readonly projectAccess$ = this.cluster$.pipe(
        switchMap(cluster => {
            if (cluster) return this.projectApi.getAccessLevels([cluster.project.uuid]).pipe(map(x => x[0]));
            else return of("none" as const);
        }),
        shareReplay(1),
    );
    readonly hasWriteAccess$ = this.projectAccess$.pipe(map(x => hasWriteAccess(x)));
    readonly hasAdminAccess$ = this.projectAccess$.pipe(map(x => hasAdminAccess(x)));
    readonly servers$ = new BehaviorSubject<Server[]>([]);
    readonly serversReady$ = new BehaviorSubject(false);
    readonly breadcrumb$ = this.cluster$.pipe(
        map((cluster) => {
            if (!cluster) return null;
            else return { items: [cluster.project.id, cluster.id], url: clustersPath(this.app.requireCurrentOrg()) };
        }),
    );
    readonly runtime$ = this.servers$.pipe(
        map((servers) => {
            const totalServers = servers.length;
            const availableServerCount = servers.filter(x => x.status === "running").length;
            return { totalServers, availableServerCount };
        }),
    );
    readonly availabilityText$ = combineLatest([this.cluster$, this.runtime$]).pipe(
        map(([cluster, runtime]) => {
            if (!cluster) return ``;
            const meaningfulStatuses: ClusterStatus[] = ["starting", "running", "resuming"];
            if (meaningfulStatuses.includes(cluster.status)) {
                return `${runtime.availableServerCount} / ${runtime.totalServers}`;
            } else return new ClusterStatusPipe().transform(cluster.status);
        }),
    );
    readonly cannotSuspendReason$ = combineLatest([this.cluster$, this.hasWriteAccess$]).pipe(map(([cluster, canWrite]) => {
        if (!cluster) return LOADING;
        if (!isInSuspendableState(cluster)) return "Cluster is not running";
        if (!canWrite) return `You don't have write access to the project '${cluster.project.id}'`;
        return null;
    }), startWith(LOADING));
    readonly cannotResumeReason$ = combineLatest([this.cluster$, this.hasWriteAccess$]).pipe(map(([cluster, canWrite]) => {
        if (!cluster) return LOADING;
        if (!isInResumableState(cluster)) return "Cluster is not suspended";
        if (!canWrite) return `You don't have write access to the project '${cluster.project.id}'`;
        return null;
    }), startWith(LOADING));
    readonly cannotDestroyReason$ = combineLatest([this.cluster$, this.hasAdminAccess$]).pipe(map(([cluster, isAdmin]) => {
        if (!cluster) return LOADING;
        if (!isInDestroyableState(cluster)) return "Cluster is not in a destroyable state";
        if (!isAdmin) return `You don't have admin access to the project '${cluster.project.id}'`;
        return null;
    }), startWith(LOADING));
    readonly cannotConnectReason$ = this.cluster$.pipe(map((cluster) => {
        if (!cluster) return LOADING;
        else if (!isInConnectableState(cluster)) return "Cannot connect to a non-running cluster"
        return null;
    }))
    readonly suspendEnabled$ = this.cannotSuspendReason$.pipe(map(x => !x));
    readonly resumeEnabled$ = this.cannotResumeReason$.pipe(map(x => !x));
    readonly destroyEnabled$ = this.cannotDestroyReason$.pipe(map(x => !x));
    readonly connectEnabled$ = this.cannotConnectReason$.pipe(map(x => !x));
    readonly latestCompatibleTypeDBVersion$ = this.cluster$.pipe(
        filter(cluster => !!cluster),
        switchMap(cluster => this.clusterApi.getLatestCompatibleTypeDBVersion(cluster!.typeDBVersion.version)),
        shareReplay(1)
    );
    readonly typeDBUpgradeAvailable$ = combineLatest([this.cluster$, this.latestCompatibleTypeDBVersion$]).pipe(
        map(([cluster, latestVersion]) => {
            return cluster ? cluster.typeDBVersion.version !== latestVersion : false
        })
    );

    readonly serverColumns = ["address", "serverStatus", "status"];

    private readonly unsub$ = new Subject<void>();
    readonly availability$: Observable<ResourceAvailability> = this.cluster$.pipe(map(x => x ? "ready" : "loading"), catchError(() => of("failed" as const)));

    dialogOpen = false;
    password: string | undefined = undefined;

    constructor(
        private app: ApplicationState, private route: ActivatedRoute, private clusterApi: ClusterApi,
        private router: Router, private snackbar: SnackbarService, private dialog: MatDialog,
        private projectApi: ProjectApi, private destroyRef: DestroyRef, private resourceService: ResourceService,
    ) {
        this.cluster$.pipe(
            takeUntilDestroyed(),
            filter(cluster => !!cluster),
            map(cluster => cluster!),
            tap(cluster => (window as any)["cluster"] = cluster),
            switchMap((cluster) => interval(5000).pipe(
                takeUntilDestroyed(this.destroyRef),
                startWith(-1),
                switchMap(() => this.clusterApi.listServers(cluster.uuid))
            )),
        ).subscribe((servers) => {
            this.servers$.next(servers);
            this.serversReady$.next(true);
        });

        this.route.queryParamMap.pipe(
            filter((params) => params.get(DIALOG) === CONNECT),
            map((params) => params.get(SETUP) === TRUE)
        ).subscribe((isSetup: boolean) => {
            this.openConnectDialog(isSetup);
        });
    }

    openConnectDialog(isSetup: boolean) {
        this.dialogOpen = true;
        this.dialog.open(ClusterConnectDialogComponent, {
            data: {
                cluster$: this.cluster$.pipe(
                    filter(cluster => !!cluster),
                    map(cluster => cluster!),
                ),
                servers$: this.servers$,
                password: this.password,
                updatePassword: (newPassword: string | undefined) => this.password = newPassword
            },
            width: "700px",
            maxWidth: "100%",
            maxHeight: "100vh",
            disableClose: isSetup,
        }).beforeClosed().subscribe(() => this.dialogOpen = false);
    }

    ngOnInit() {
        this.initDataLoader();
    }

    ngOnDestroy() {
        this.unsub$.next();
    }

    initDataLoader() {
        this.route.params.pipe(
            filter((params) => params["project-id"] && params["cluster-id"]),
            map((params) => ({ projectId: params["project-id"] as string, clusterId: params["cluster-id"] as string })),
            distinctUntilChanged((a, b) => a.clusterId === b.clusterId && a.projectId === b.projectId),
            tap(() => { this.unsub$.next(); }),
            switchMap(({ projectId, clusterId }) => {
                return this.projectApi.getProject({ projectId, orgUuid: this.app.requireCurrentOrg().uuid }, of(undefined)).pipe(
                    first(),
                    map((project) => ({ project: project.initial!, clusterId })),
                );
            }),
            switchMap(({ project, clusterId }) => {
                return this.clusterApi.getCluster({ clusterId: clusterId, projectUuid: project.uuid }, this.unsub$);
            }),
        ).subscribe({
            next: (res) => {
                this.resourceService.processResponse({ resource$: this.cluster$, res: res, unsub$: this.unsub$, onResync: () => this.initDataLoader() });
            },
            error: (err) => {
                this.cluster$.error(err);
            }
        });
    }

    openEditModal() {
        this.dialog.open<ClusterUpdateDialogComponent, ClusterEditDialogData, DialogResult>(ClusterUpdateDialogComponent, {
            data: { cluster: this.cluster$.value! }
        }).beforeClosed().subscribe((result) => {
            if (result === "ok") this.initDataLoader();
        });
    }

    openSuspendResumeModal(action: "resume" | "suspend") {
        const cluster = this.cluster$.value!;
        const modal = this.dialog.open<SuspendResumeModalComponent, SuspendResumeModalData>(SuspendResumeModalComponent, {
            data: { cluster, action },
        }).componentInstance;
        modal.confirmed.pipe(
            switchMap(() => {
                switch (action) {
                    case "suspend": return this.clusterApi.suspendCluster(cluster.uuid);
                    case "resume": return this.clusterApi.resumeCluster(cluster.uuid);
                }
            }),
        ).subscribe({
            next: () => {
                modal.close();
                switch (action) {
                    case "suspend": this.snackbar.success(`'${cluster.id}' is now being suspended.`); break;
                    case "resume": this.snackbar.success(`'${cluster.id}' is now being resumed.`); break;
                }
            },
            error: () => {
                modal.isSubmitting$.next(false);
            },
        });
    }

    openDestroyModal() {
        const cluster = this.cluster$.value!;
        const modal = this.dialog.open<StrongConfirmationModalComponent, StrongConfirmationModalData>(StrongConfirmationModalComponent, {
            data: {
                title: "Destroy cluster",
                body: `Are you sure you would like to destroy '${cluster.id}'? All of its data will be permanently erased. This action cannot be undone!`,
                confirmText: "Destroy cluster",
                strongConfirmationString: cluster.id,
            },
        }).componentInstance;
        modal.confirmed.pipe(switchMap(() => this.clusterApi.destroyCluster(cluster.uuid))).subscribe({
            next: () => {
                modal.close();
                this.snackbar.success(`'${cluster.id}' is now being destroyed.`);
                this.router.navigate([clustersPath(this.app.requireCurrentOrg())]);
            },
            error: () => {
                modal.isSubmitting$.next(false);
            },
        });
    }

    openUpdateTypeDBModal() {
        this.latestCompatibleTypeDBVersion$.pipe(first()).subscribe((latestCompatibleTypeDBVersion) => {
            const cluster = this.cluster$.value!;
            const modal = this.dialog.open<ConfirmationModalComponent, ConfirmationModalData>(ConfirmationModalComponent, {
                data: {
                    title: "Update TypeDB",
                    body: `Are you sure you would like to update '${cluster.id}' to TypeDB ${latestCompatibleTypeDBVersion}?`,
                    confirmText: `Update to ${latestCompatibleTypeDBVersion}`,
                    confirmButtonStyle: "primary-solid green",
                },
            }).componentInstance;
            modal.confirmed.pipe(
                switchMap(() => this.clusterApi.updateToLatestCompatibleTypeDB(cluster.uuid)),
            ).subscribe({
                next: () => {
                    modal.close();
                    this.snackbar.success(`'${cluster.id}' is now being updated to TypeDB ${latestCompatibleTypeDBVersion}.`);
                },
                error: () => {
                    modal.isSubmitting$.next(false);
                },
            });
        });
    }

    serverIcon(server: Server, dialogOpen: boolean): StatusIcon {
        switch (server.status) {
            case "running":
                return { color: "ok" };
            case "pending":
                return { loading: true, paused: dialogOpen };
            case "unknown":
            case "error":
                return { color: "error" };
        }
    }

    handleServerTableRowClick(server: Server) {
        if (!isBlank(window.getSelection()?.toString())) return; // handle case where user wants to select the address
        this.router.navigate([serverDetailsPath(server, this.cluster$.value!, this.app.requireCurrentOrg())]);
    }
}
