<div class="tp-email-verification">
  @switch (state.status) {
    @case ("pending") {
      <tp-spinner/>
    }
    @case ("emailSent") {
      <img class="tp-logo" src="https://typedb.com/assets/logo/typedb.svg" alt="TypeDB"/>

      <h1>Please verify your email</h1>

      <div class="tp-big-envelope"><i class="fa-thin fa-envelope"></i></div>

      <p class="text-p2" style="margin-top: 40px">A link to verify your email has been sent to <strong>{{ state.email }}</strong></p>

      <hr/>

      <p class="text-aside">
        Didn't receive it?
        <button class="button-secondary td-button-size-s" (click)="resendVerificationEmail()">Resend email</button>
      </p>
      <p class="text-aside">
        Not your account?
        <button class="button-secondary td-button-size-s" (click)="signOut()">Change email address</button>
      </p>
    }
  }
</div>
