<div class="tp-split-panes">
  <div class="tp-primary-pane">
    <div class="tp-login tp-content-area">
      @switch (pageSlug$ | async) {
        @case ("sign-in") {
          <div class="tp-login-form-header">
            <img class="tp-logo" src="https://typedb.com/assets/logo/typedb.svg" alt="TypeDB"/>
            <h1>Sign in to TypeDB Cloud</h1>
            <p>Or <a routerLink="/sign-up" [queryParams]="{ email: signInForm.value.email }">sign up</a> for a new account</p>
          </div>

          <div class="tp-sso-buttons">
            <button (click)="googleSignIn()" id="googleSignIn"><img src="/assets/logo/google.svg" alt=""/>Google</button>
            <button (click)="microsoftSignIn()" id="microsoftSignIn"><img src="/assets/logo/microsoft.svg" alt=""/>Microsoft</button>
            <button (click)="githubSignIn()" id="githubSignIn"><img src="/assets/logo/github.svg" alt=""/>GitHub</button>
          </div>

          <div class="tp-login-method-separator">
            <hr/> or <hr/>
          </div>

          <tp-form class="tp-login-form-body" [formGroup]="signInForm" [isSubmitting$]="isSubmitting$" (doSubmit)="onSubmitSignInForm()">
            <tp-form-input type="email" autocomplete="username" [form]="signInForm" label="Email" field="email"/>
            <div class="tp-login-form-password-row">
              <tp-form-password-input autocomplete="current-password" [form]="signInForm" label="Password" field="password"/>
              <tp-form-actions class="tp-sign-in-actions" [isSubmitting]="isSubmitting$ | async" submitText="Sign in" buttonIdPrefix="signIn" [submitDisabled]="!signInForm.dirty"/>
            </div>
            <p>
              <a class="tp-forgot-password-link tp-link-quiet" routerLink="/forgot-password" id="forgotPassword">Forgot password</a>
            </p>
          </tp-form>
        }

        @case ("sign-up") {
          <div class="tp-login-form-header">
            <img class="tp-logo" src="https://typedb.com/assets/logo/typedb.svg" alt="TypeDB"/>
            <h1>Sign up to TypeDB Cloud</h1>
            <p>Or <a routerLink="/sign-in" [queryParams]="{ email: signUpForm.value.email }">sign in</a> to your existing account</p>
          </div>

          <div class="tp-sso-buttons">
            <button (click)="googleSignIn()" id="googleSignUp"><img src="/assets/logo/google.svg" alt=""/>Google</button>
            <button (click)="microsoftSignIn()" id="microsoftSignUp"><img src="/assets/logo/microsoft.svg" alt=""/>Microsoft</button>
            <button (click)="githubSignIn()" id="githubSignUp"><img src="/assets/logo/github.svg" alt=""/>GitHub</button>
          </div>

          <div class="tp-login-method-separator">
            <hr/> or <hr/>
          </div>

          <tp-form class="tp-login-form-body" [formGroup]="signUpForm" [isSubmitting$]="isSubmitting$" (doSubmit)="onSubmitSignUpForm()">
            <tp-form-input type="email" autocomplete="username" [form]="signUpForm" label="Email" field="email"/>
            <div class="tp-login-form-password-row">
              <tp-form-password-input autocomplete="current-password" [form]="signUpForm" label="Password" field="password"/>
              <tp-form-actions class="tp-sign-in-actions" [isSubmitting]="isSubmitting$ | async" submitText="Sign up" buttonIdPrefix="signUp" [submitDisabled]="!signUpForm.dirty"/>
            </div>
            <aside class="terms-privacy-additional-info">
              By signing up for TypeDB Cloud, you are accepting the
              <a class="tos-privacy-link" href="https://typedb.com/legal/terms-of-service" target="_blank" rel="noopener">Terms of Service</a>
              and the
              <a class="tos-privacy-link" href="https://typedb.com/legal/privacy-policy" target="_blank" rel="noopener">Privacy Policy</a>.
            </aside>
          </tp-form>
        }

        @case ("verify-email") {
          <tp-email-verification/>
        }

        @case ("forgot-password") {
          <tp-forgot-password/>
        }
      }
    </div>
  </div>
  <div class="tp-secondary-pane">
    <div class="tp-promotional-area tp-content-area">
      @if (loginPortal$ | async; as loginPortal) {
        <tp-heading-with-highlights class="tp-promotional-title" level="h2" [value]="loginPortal.title"/>

        <tp-p-with-highlights class="tp-headline" [value]="loginPortal.subtitle"/>

        <ul>
          @for (keyPoint of loginPortal.keyPoints; track keyPoint) {
            <li><div><i class="fa-solid fa-check"></i> {{ keyPoint }}</div></li>
          }
        </ul>
      }
    </div>
  </div>
</div>
