@if (table.ready$ | async) {
  <table mat-table [dataSource]="table.items$" matSort (matSortChange)="tableService.handleMatSortChange(table, $event)">
    <ng-container matColumnDef="select">
      <th mat-header-cell *matHeaderCellDef><tp-table-selection-header-cell [table]="table"/></th>
      <td mat-cell *matCellDef="let cluster"><tp-table-selection-cell [table]="table" [row]="cluster"/></td>
    </ng-container>
    <ng-container matColumnDef="id">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ table.columns.id.name }}</th>
      <td mat-cell *matCellDef="let cluster">{{ cluster.id }}</td>
    </ng-container>
    <ng-container matColumnDef="project">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ table.columns.project.name }}</th>
      <td mat-cell *matCellDef="let cluster">
        <a (click)="openProject(cluster.project); $event.stopPropagation();">{{ cluster.project.id }}</a>
      </td>
    </ng-container>
    <ng-container matColumnDef="provider">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ table.columns.provider.name }}</th>
      <td mat-cell *matCellDef="let cluster">{{ cluster.provider.name }}</td>
    </ng-container>
    <ng-container matColumnDef="region">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ table.columns.region.name }}</th>
      <td mat-cell *matCellDef="let cluster">{{ cluster.region | region:'short' }}</td>
    </ng-container>
    <ng-container matColumnDef="machineType">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ table.columns.machineType.name }}</th>
      <td mat-cell *matCellDef="let cluster">{{ cluster.machineType.vendorId }}</td>
    </ng-container>
    <ng-container matColumnDef="clusterSize">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ table.columns.clusterSize.name }}</th>
      <td mat-cell *matCellDef="let cluster">{{ cluster.serverCount }}</td>
    </ng-container>
    <ng-container matColumnDef="storageType">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ table.columns.storageType.name }}</th>
      <td mat-cell *matCellDef="let cluster">{{ cluster.storageType.vendorId }}</td>
    </ng-container>
    <ng-container matColumnDef="storageSize">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ table.columns.storageSize.name }}</th>
      <td mat-cell *matCellDef="let cluster">{{ cluster.storageGB }}</td>
    </ng-container>
    <ng-container matColumnDef="clusterStatus">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ table.columns.clusterStatus.name }}</th>
      <td mat-cell *matCellDef="let cluster">{{ cluster.status | clusterStatus }}</td>
    </ng-container>
    <ng-container matColumnDef="typeDBVersion">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ table.columns.typeDBVersion.name }}</th>
      <td mat-cell *matCellDef="let cluster">{{ cluster.typeDBVersion.version }}</td>
    </ng-container>
    <ng-container matColumnDef="createdAt">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ table.columns.createdAt.name }}</th>
      <td mat-cell *matCellDef="let cluster">{{ cluster.createdAt | datetime }}</td>
    </ng-container>
    <ng-container matColumnDef="status">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let cluster"><tp-table-status-cell [status]="clusterIcon(cluster)"/></td>
    </ng-container>
    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let cluster">
        <button
          mat-fab aria-label="more" [disabled]="!rowPopupMenuItems!(cluster, (table.accessLevelByUuid$(cluster.uuid) | async)!).length"
          [matMenuTriggerFor]="rowPopupMenu" [matMenuTriggerData]="{ items: rowPopupMenuItems!(cluster, (table.accessLevelByUuid$(cluster.uuid) | async)!) }"
          (click)="$event.stopPropagation()"
        >
          <i class="fa-light fa-ellipsis"></i>
        </button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="table.displayedColumns"></tr>
    <tr
      mat-row *matRowDef="let cluster; columns: table.displayedColumns"
      [class]="tableService.rowClass$(table, cluster) | async"
      (click)="tableService.handleRowClick(table, cluster)"
    ></tr>
  </table>
} @else {
  <tp-spinner/>
}

<mat-menu #rowPopupMenu="matMenu">
  <ng-template matMenuContent let-items="items">
    @for (item of items; track item) {
      <button mat-menu-item [matTooltip]="item.disabledReason" [disabled]="item.disabled" (click)="item.action()">{{ item.label }}</button>
    }
  </ng-template>
</mat-menu>
