<mat-form-field>
  @if (label) {
    <mat-label>{{ label }}</mat-label>
  }
  <input #input matInput [formControl]="formControl" [type]="revealed ? 'text' : 'password'">
  <div class="tp-password-input-suffix" matSuffix>
    <button type="button" tabindex="-1" mat-icon-button (click)="revealed = !revealed" aria-label="Hide password" aria-pressed="hide">
      <i [class]="revealed ? 'fa-light fa-eye-slash' : 'fa-light fa-eye'"></i>
    </button>
    <ng-content select="[inputSuffix]"/>
  </div>
  @if (formControl.getError("errorText")) {
    <mat-error>{{ formControl.getError("errorText") }}</mat-error>
  }
</mat-form-field>
