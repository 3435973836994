/*
 * This unpublished material is proprietary to Vaticle.
 * All rights reserved. The methods and
 * techniques described herein are considered trade secrets
 * and/or confidential. Reproduction or distribution, in whole
 * or in part, is forbidden except by express written permission
 * of Vaticle.
 */

import { Component, EventEmitter, Output, QueryList, ViewChild, ViewChildren } from "@angular/core";
import { MatExpansionModule, MatExpansionPanel } from "@angular/material/expansion";
import { SpinnerComponent, ButtonComponent, FormActionsComponent, FormOptionGroup, FormOption } from "typedb-platform-framework";
import { CloudRegion, isCustomQuestion, isMultipleChoiceQuestion, MultipleChoiceQuestion, ParagraphWithHighlights, ProviderRegionData } from "typedb-web-schema/lib";
import { ParagraphWithHighlightsComponent } from "../../../framework/text/text-with-highlights.component";
import { RETURN_TO } from "../../../framework/url-params";
import { AnalyticsService } from "../../../service/analytics.service";
import { ApplicationState } from "../../../service/application-state.service";
import { IdentityAuthService } from "../../../service/authentication/identity-auth.service";
import { PlatformAuthService } from "../../../service/authentication/platform-auth.service";
import { SanityService } from "../../../service/sanity.service";
import { UserApi } from "../../../service/user/user-api.service";
import { CloudRegionQuestionComponent } from "./question/cloud-region/cloud-region-question.component";
import { MultipleChoiceQuestionComponent } from "./question/multiple-choice/multiple-choice-question.component";
import { AsyncPipe } from "@angular/common";
import { combineLatest, first, map, switchMap, tap } from "rxjs";
import { MatIconModule } from "@angular/material/icon";
import { MatTooltipModule } from "@angular/material/tooltip";
import {
    CLOUD_PROVIDER_QUESTION_POSTHOG_PROPERTY, CLOUD_REGION_QUESTION_ID, CloudRegionQuestionInstance,
    MultipleChoiceQuestionInstance, QuestionInstance, SurveyInstance, SurveySectionInstance
} from "./survey-instance";
import { ActivatedRoute, Router } from "@angular/router";

@Component({
    selector: "tp-setup-project",
    templateUrl: "./setup-project.component.html",
    styleUrls: ["./setup-project.component.scss"],
    standalone: true,
    imports: [
        MultipleChoiceQuestionComponent, AsyncPipe, SpinnerComponent, MatExpansionModule,
        MatIconModule, ButtonComponent, MatTooltipModule, FormActionsComponent, CloudRegionQuestionComponent, ParagraphWithHighlightsComponent
    ],
})
export class SetupProjectComponent {
    @Output() done = new EventEmitter();

    @ViewChild(CloudRegionQuestionComponent) cloudRegionQuestionComponent?: CloudRegionQuestionComponent;
    @ViewChildren(MatExpansionPanel) expansionPanels!: QueryList<MatExpansionPanel>;
    survey?: SurveyInstance;
    cloudProviderQuestion?: MultipleChoiceQuestionInstance;
    cloudRegionOptionGroups?: FormOptionGroup<CloudRegion>[];
    regionQuestionIsFreeText = false;
    providerRegionData?: ProviderRegionData;
    isSubmitting = false;
    tagline$ = this.sanity.onboarding.pipe(map(x => x.projectInfoText ? ParagraphWithHighlights.fromSanity(x.projectInfoText) : null));
    continueLink?: string;

    constructor(
        private sanity: SanityService, private userApi: UserApi, private identityAuth: IdentityAuthService,
        private platformAuth: PlatformAuthService, private app: ApplicationState, private analytics: AnalyticsService,
        private route: ActivatedRoute, private router: Router
    ) {
        sanity.projectInfoSurvey.pipe(first()).subscribe((survey) => {
            this.survey = new SurveyInstance(survey);
            this.initCloudProviderQuestion();
        });

        sanity.providerRegionData.pipe(first()).subscribe((data) => {
            this.providerRegionData = data;
        });

        combineLatest([this.route.data, this.route.queryParamMap]).subscribe(([data, params]) => {
            if (data["variant"] === "standalone") {
                const returnToUrl = params.get(RETURN_TO);
                this.continueLink = returnToUrl ?? "/";
            }
        });
    }

    private initCloudProviderQuestion() {
        const cloudProviderQuestion = this.survey!.sections
            .flatMap(x => x.questions)
            .find(x => (x.spec as MultipleChoiceQuestion).posthogProperty === CLOUD_PROVIDER_QUESTION_POSTHOG_PROPERTY);
        if (!cloudProviderQuestion) throw `Cloud provider question not found. Its property key should be '${CLOUD_PROVIDER_QUESTION_POSTHOG_PROPERTY}'`;
        this.cloudProviderQuestion = cloudProviderQuestion as MultipleChoiceQuestionInstance;
    }

    expandSection(sectionIndex: number) {
        this.expansionPanels.get(sectionIndex)!.open();
    }

    onQuestionAnswerChanged(section: SurveySectionInstance, question: QuestionInstance) {
        if (section.isCompleted && !this.survey!.firstCompletions.has(section.spec.name)) {
            this.survey!.firstCompletions.add(section.spec.name);
            if (this.shouldAutoAdvanceOnAnswerChanged(question)) { // NOTE: Auto-advance will not trigger if the last question answered in a section is multi-choice
                this.doAutoAdvance(section);
            }
        }

        if (question instanceof MultipleChoiceQuestionInstance && question.spec.posthogProperty === CLOUD_PROVIDER_QUESTION_POSTHOG_PROPERTY) {
            this.updateCloudRegionOptions();
        }
    }

    private updateCloudRegionOptions() {
        const providerId = this.selectedProviderId();
        if (providerId === "other") {
            this.regionQuestionIsFreeText = true;
            this.cloudRegionQuestionComponent!.selectedOption = undefined;
        } else {
            this.regionQuestionIsFreeText = false;
            this.cloudRegionQuestionComponent!.freeTextValueRaw = "";
            const continentNames = [...new Set(this.providerRegionData!.continents
                .sort((x, y) => x.ordinal - y.ordinal)
                .map(x => x.name)
            )];
            const regionMap = Object.fromEntries(continentNames.map(x => [x, [] as FormOption<CloudRegion>[]]));
            const provider = this.providerRegionData!.providers.find(x => x.id === providerId);
            if (!provider) throw `Invalid cloud provider ID: ${providerId}`;
            provider!.regions.forEach(region => {
                const formOption = this.regionOptionOf(region, providerId!);
                regionMap[region.country.continent].push(formOption);
            });
            this.cloudRegionOptionGroups = Object.entries(regionMap).map(([name, options]) => ({ name, options }));
        }
        this.cloudRegionQuestionComponent!.question.answer = null;
    }

    private selectedProviderId(): "gcp" | "aws" | "azure" | "other" | null {
        const provider = this.cloudProviderQuestion?.answers[0]?.toLowerCase();
        if (!provider?.trim().length) return null;
        if (provider.includes("google") || provider.includes("gcp")) return "gcp";
        if (provider.includes("amazon") || provider.includes("aws")) return "aws";
        if (provider.includes("azure")) return "azure";
        else return "other";
    }

    private regionOptionOf(region: CloudRegion, providerId: "gcp" | "aws" | "azure"): FormOption<CloudRegion> {
        let viewValue: string;
        switch (providerId) {
            case "gcp": viewValue = `${region.location} (${region.vendorId})`; break;
            case "aws": viewValue = `${region.location} (${region.vendorId})`; break;
            case "azure": viewValue = region.vendorId; break;
        }
        return { value: region, viewValue: viewValue };
    }

    private shouldAutoAdvanceOnAnswerChanged(question: QuestionInstance) {
        return !(isMultipleChoiceQuestion(question.spec) && question.spec.isMultiSelect);
    }

    private doAutoAdvance(completedSection: SurveySectionInstance) {
        const completedSectionIndex = this.survey!.sections.findIndex(x => x.spec.name === completedSection.spec.name);
        const nextIncompleteSectionIndexRelative = this.survey!.sections.slice(completedSectionIndex).findIndex(x => !this.survey!.firstCompletions.has(x.spec.name));
        if (nextIncompleteSectionIndexRelative === -1) {
            // TODO: enable Continue button if all sections are completed; highlight all incomplete sections
        } else {
            this.expansionPanels.get(completedSectionIndex + nextIncompleteSectionIndexRelative)!.open();
        }
    }

    get finishButtonTooltip(): string {
        return this.survey?.isCompleted ? `` : `All questions must be answered in order to proceed`;
    }

    completeSurvey() {
        this.isSubmitting = true;
        this.userApi.completeOnboardingSurvey().pipe(
            switchMap(() => this.identityAuth.getIdToken()),
            switchMap((token) => this.platformAuth.authUserToken(token)),
            tap((userAuth) => this.app.userAuth$.next(userAuth)),
        ).subscribe({
            next: () => {
                this.isSubmitting = false;
                this.analytics.google.reportAdConversion("submitProjectInfo");
                this.done.emit();
                if (this.continueLink != null) this.router.navigate([this.continueLink]);
            },
            error: () => {
                this.isSubmitting = false;
            },
        });
    }

    isMultipleChoiceQuestion(question: QuestionInstance): question is MultipleChoiceQuestionInstance {
        return isMultipleChoiceQuestion(question.spec);
    }

    isCloudRegionQuestion(question: QuestionInstance): question is CloudRegionQuestionInstance {
        return isCustomQuestion(question.spec) && question.spec.customId === CLOUD_REGION_QUESTION_ID;
    }
}
