/*
 * This unpublished material is proprietary to Vaticle.
 * All rights reserved. The methods and
 * techniques described herein are considered trade secrets
 * and/or confidential. Reproduction or distribution, in whole
 * or in part, is forbidden except by express written permission
 * of Vaticle.
 */

import { AsyncPipe, NgClass } from "@angular/common";
import { Component, OnDestroy, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { BehaviorSubject, Observable, Subject, map, of } from "rxjs";
import {
    BreadcrumbComponent, ButtonComponent, ConfirmationModalComponent, ContactSupportDialogData,
    ContactSupportModalComponent, DatetimePipe, DeleteResourceSectionComponent, IntegrationCardComponent, ModalComponent,
    PropertiesTableComponent, PropertiesTableRowComponent, SpinnerComponent
} from "typedb-platform-framework";
import { mergeChanges } from "../../../concept/api-response";
import { AWSAccount, AzureAccount, CreditCard, GCPAccount, Invoice } from "../../../concept/subscription";
import { BillingAddressPipe } from "../billing-address.pipe";
import { CreditCardExpiryDatePipe } from "../credit-card-expiry-date.pipe";
import { CreditCardTypePipe } from "../credit-card-type.pipe";
import { CurrencyPipe } from "../currency.pipe";
import { PaymentDetailsModalComponent } from "../payment-details-modal/payment-details-modal.component";
import { DialogResult } from "../../../service/dialog.service";
import { OrgUpdateDialogComponent } from "../update/org-update-dialog.component";

import { SubscriptionApi } from "../../../service/subscription/subscription-api.service";
import { ApplicationState } from "../../../service/application-state.service";
import { SnackbarService } from "../../../service/snackbar.service";
import { SubscriptionController } from "../../../service/subscription/subscription-controller.service";
import { ResourceAvailability, PageScaffoldComponent } from "../../scaffold/page/page-scaffold.component";
import { InvoicesTableComponent } from "../invoices-table/invoices-table.component";
import { TablePaginatorComponent } from "../../../framework/table";
import { MatTooltipModule } from "@angular/material/tooltip";
import { PaymentMethodStatusPipe } from "../payment-method-status.pipe";
import {
    MarketplaceCardsComponent
} from "../marketplace-cards/marketplace-cards.component";
import { CreditsTableComponent } from "../credits-table/credits-table.component";
import { DiscountsTableComponent } from "../discounts-table/discounts-table.component";

@Component({
    selector: "tp-org-settings-page",
    templateUrl: "./org-settings-page.component.html",
    styleUrls: ["./org-settings-page.component.scss"],
    standalone: true,
    imports: [
        PageScaffoldComponent, BreadcrumbComponent, PropertiesTableComponent, DeleteResourceSectionComponent,
        ModalComponent, ConfirmationModalComponent, AsyncPipe, ButtonComponent, SpinnerComponent,
        InvoicesTableComponent, TablePaginatorComponent, PaymentDetailsModalComponent, PropertiesTableRowComponent,
        CreditCardTypePipe, CreditCardExpiryDatePipe, BillingAddressPipe, DatetimePipe, CurrencyPipe,
        MatTooltipModule, PaymentMethodStatusPipe, NgClass, IntegrationCardComponent, SpinnerComponent,
        MarketplaceCardsComponent, CreditsTableComponent, DiscountsTableComponent,
    ],
})
export class OrgSettingsPageComponent implements OnInit, OnDestroy {
    readonly org = this.app.requireCurrentOrg();
    readonly breadcrumb$ = of({ items: [this.org.id], url: `/` });

    readonly upcomingInvoice$ = new BehaviorSubject<Invoice | "none" | null>(null);
    readonly creditCard$ = new BehaviorSubject<CreditCard | null>(null);
    readonly activeGcpAccount$ = new BehaviorSubject<GCPAccount | null>(null);
    readonly azureAccount$ = new BehaviorSubject<AzureAccount | null>(null);
    readonly activeAwsAccount$ = new BehaviorSubject<AWSAccount | null>(null);
    private readonly upcomingInvoiceUnsub$ = new Subject<void>();
    readonly availability$: Observable<ResourceAvailability> = this.app.hasOrgAdminAccess$.pipe(map(x => x ? "ready" : "loading"));

    private readonly unsubInvoices$ = new Subject<void>();
    readonly invoices = this.subscriptionCtl.invoicesTable(this.unsubInvoices$);
    private readonly unsubCredits$ = new Subject<void>();
    readonly credits = this.subscriptionCtl.creditsTable(this.unsubCredits$);
    private readonly unsubDiscounts$ = new Subject<void>();
    readonly discounts = this.subscriptionCtl.discountsTable(this.unsubDiscounts$);

    cardSectionReady = false;

    constructor(
        private app: ApplicationState, private snackbar: SnackbarService, private dialog: MatDialog,
        private subscriptionApi: SubscriptionApi, private subscriptionCtl: SubscriptionController,
    ) {}

    ngOnInit() {
        this.initUpcomingInvoiceLoader();
        this.fetchCreditCards();
        this.fetchMarketplaceAccounts();
    }

    ngOnDestroy() {
        this.upcomingInvoiceUnsub$.next();
        this.unsubInvoices$.next();
        this.unsubCredits$.next();
    }

    openEditOrgDialog() {
        this.dialog.open(OrgUpdateDialogComponent);
    }

    showContactSupportDialog() {
        this.dialog.open<ContactSupportModalComponent, ContactSupportDialogData>(ContactSupportModalComponent, { data: { title: "Delete organization" } });
    }

    initUpcomingInvoiceLoader() {
        const org = this.app.requireCurrentOrg();
        this.subscriptionApi.getUpcomingInvoice(org.uuid, this.upcomingInvoiceUnsub$).subscribe((res) => {
            if (!res) {
                this.upcomingInvoice$.next("none");
                return;
            }
            const current = this.upcomingInvoice$.value === "none" ? null : this.upcomingInvoice$.value;
            const mergeResult = mergeChanges(current, res);
            if (mergeResult.result === "shouldDelete") {
                this.upcomingInvoiceUnsub$.next();
                // TODO: ???
            } else if (mergeResult.result === "shouldResync") {
                console.warn(`Received update is not valid in the current state; forcing a full resync`);
                this.upcomingInvoiceUnsub$.next();
                this.initUpcomingInvoiceLoader();
                return;
            }
            if (mergeResult.data) {
                this.upcomingInvoice$.next(mergeResult.data);
            }
        });
    }

    openPaymentModal() {
        this.dialog.open<PaymentDetailsModalComponent, any, DialogResult>(PaymentDetailsModalComponent).afterClosed().subscribe(result => {
            if (result === "ok") {
                this.fetchCreditCards();
                this.snackbar.success("Payment method added successfully");
            }
            else if (result == null || result === "cancelled") return;
            else this.snackbar.errorPersistent(`Error setting up payment method: ${result.error}`);
        });
    }

    private fetchCreditCards() {
        const org = this.app.requireCurrentOrg();
        this.subscriptionApi.listCreditCards(org.uuid).subscribe((cards) => {
            this.creditCard$.next(cards[0]);
            this.cardSectionReady = true
        });
    }

    private fetchMarketplaceAccounts() {
        const org = this.app.requireCurrentOrg();
        this.subscriptionApi.getMarketplaceAccounts(org.uuid).subscribe((accounts) => {
            if (accounts.gcp?.status == "active") {
                this.activeGcpAccount$.next(accounts.gcp);
            }
            this.azureAccount$.next(accounts.azure);
            if (accounts.aws?.status == "active") {
                this.activeAwsAccount$.next(accounts.aws);
            }
        });
    }

    // TODO
    // deleteOrg() {
    //     const currentOrg = this.app.requireCurrentOrg();
    //     this.orgService.deleteOrg(currentOrg.uuid).subscribe(() => {
    //         this.snackbar.success(`Organization '${currentOrg.id}' is now deleted.`);
    //         this.router.navigate(["/"]);
    //     });
    // }
}
