<tp-page-scaffold [pageAvailability]="availability$ | async">
  <div class="action-bar">
    @if (breadcrumb$ | async; as breadcrumb) {
      <tp-breadcrumb [items]="breadcrumb.items" [url]="breadcrumb.url"/>
    }
  </div>
  @if (orgMember$ | async; as orgMember) {
    <article>
      <section>
        <h2 class="section-title-row">{{ orgMember.firstName }} {{ orgMember.lastName }} ({{orgMember.email}})</h2>
        <tp-properties-table title="User Details">
          <tp-properties-table-row key="User ID">{{ orgMember.id }}</tp-properties-table-row>
          <tp-properties-table-row key="Email">{{ orgMember.email }}</tp-properties-table-row>
          <tp-properties-table-row key="First Name">{{ orgMember.firstName }}</tp-properties-table-row>
          <tp-properties-table-row key="Last Name">{{ orgMember.lastName }}</tp-properties-table-row>
          <tp-properties-table-row key="Organization Access Level">
            {{ orgMember.accessLevel | accessLevel }}
            @if (app.hasOrgAdminAccess$ | async) {
              <div class="flex-spacer"></div>
              <tp-button buttonStyle="primary-outline white stroke" [height]="32" (click)="openSetAccessLevelModal(orgMember)" buttonId="editOrgAccessLevel">
                <i class="fa-light fa-pen-to-square"></i> Edit
              </tp-button>
            }
          </tp-properties-table-row>
        </tp-properties-table>
      </section>

      @if (projects) {
        <section>
          <tp-table-toolbar [table]="projects" filterGroupId="projects" tableTitle="Projects"/>
          <tp-projects-table [table]="projects"/>
          <tp-table-paginator [table]="projects"/>
        </section>
      }

      @if (teams) {
        <section>
          <tp-table-toolbar [table]="teams" filterGroupId="teams" tableTitle="Teams"/>
          <tp-teams-table [table]="teams"/>
          <tp-table-paginator [table]="teams"/>
        </section>
      }

      <tp-delete-resource-section
        title="Remove this user" [display]="{ text: 'Remove the user from this organization.' }"
        buttonText="Remove" (buttonPressed)="beginRemoveUser()"
      />
    </article>
  }
</tp-page-scaffold>
