<div class="tp-user-info-area">
  <tp-user-avatar [userEmail]="userEmail$ | async"/>
  <div class="tp-user-info-text">
    <p><strong>{{ (userId$ | async) ?? "" }}</strong></p>
    <p>{{ (userFullName$ | async) ?? "" }}</p>
  </div>
  <div class="flex-spacer"></div>
  <button class="tp-close-btn" mat-icon-button (click)="closeClick.emit()"><i class="fa-light fa-xmark"></i></button>
</div>

<div class="links">
  @if (showAccountButton$ | async) {
    <tp-sidebar-link link="/settings" idSuffix="account"><i class="fa-light fa-user"></i><span>Account</span></tp-sidebar-link>
  }
  <tp-sidebar-link (click)="signOut()" idSuffix="signOut"><i class="fa-light fa-sign-out"></i><span>Sign out</span></tp-sidebar-link>
</div>

<div class="tp-main-spacer flex-spacer"></div>

<tp-sidebar-external-links/>
