/*
 * This unpublished material is proprietary to Vaticle.
 * All rights reserved. The methods and
 * techniques described herein are considered trade secrets
 * and/or confidential. Reproduction or distribution, in whole
 * or in part, is forbidden except by express written permission
 * of Vaticle.
 */

import { Location as NgLocation } from "@angular/common";
import { Component } from "@angular/core";
import { combineLatest, map, Observable, of, Subject, switchMap, tap } from "rxjs";
import { ActivatedRoute, Router, UrlTree, RouterLink, UrlSegment, Params } from "@angular/router";

import {
    ButtonComponent, FormActionsComponent, FormComponent, FormInputComponent, FormPasswordInputComponent,
    SpinnerComponent, passwordValidator, patternValidator, requiredValidator
} from "typedb-platform-framework";
import { emailPattern, emailPatternErrorText } from "typedb-web-common/lib";
import { ok } from "../../../concept/base";
import { HeadingWithHighlightsComponent, ParagraphWithHighlightsComponent } from "../../../framework/text/text-with-highlights.component";
import { AnalyticsService } from "../../../service/analytics.service";
import { PlatformAuthService } from "../../../service/authentication/platform-auth.service";
import { SanityService } from "../../../service/sanity.service";
import { UserApi } from "../../../service/user/user-api.service";
import { ApplicationState } from "../../../service/application-state.service";
import { IdentityAuthService } from "../../../service/authentication/identity-auth.service";
import { SnackbarService } from "../../../service/snackbar.service";
import { BannerComponent } from "../../scaffold/banner/banner.component";
import { EmailVerificationComponent } from "../email-verification/email-verification.component";
import { ForgotPasswordComponent } from "../forgot-password/forgot-password.component";
import { AsyncPipe } from "@angular/common";
import { FormBuilder } from "@angular/forms";
import { MatFormFieldModule } from "@angular/material/form-field";
import { EMAIL, LOGOUT_REASON, RETURN_TO } from "../../../framework/url-params";
import { MatIconModule } from "@angular/material/icon";
import { AuthCredential, OAuthProvider, UserCredential, getAdditionalUserInfo } from "@angular/fire/auth";
import { UserAuth } from "../../../concept/user";

const AUTHENTICATION_FAILED = "Authentication failed, please try again";

@Component({
    selector: "tp-login-page",
    templateUrl: "login-page.component.html",
    standalone: true,
    imports: [
        SpinnerComponent, RouterLink, ButtonComponent, AsyncPipe,
        FormComponent, FormActionsComponent, FormInputComponent, FormPasswordInputComponent, MatFormFieldModule,
        MatIconModule, EmailVerificationComponent, ForgotPasswordComponent, BannerComponent, HeadingWithHighlightsComponent, ParagraphWithHighlightsComponent,
    ],
})
export class LoginPageComponent {
    private redirectTo?: UrlTree;
    pageSlug$!: Observable<"sign-in" | "sign-up" | "forgot-password" | "verify-email">;
    private pendingAccountLink?: { email: string, credential: AuthCredential };
    signInForm = this.formBuilder.nonNullable.group({
        email: ["", [patternValidator(emailPattern, emailPatternErrorText), requiredValidator]],
        password: ["", [passwordValidator, requiredValidator]],
    });
    signUpForm = this.formBuilder.nonNullable.group({
        email: ["", [patternValidator(emailPattern, emailPatternErrorText), requiredValidator]],
        password: ["", [passwordValidator, requiredValidator]],
    });
    loginPortal$ = this.sanity.loginPortal;
    isSubmitting$ = new Subject<boolean>();

    constructor(
        private identityAuth: IdentityAuthService, private route: ActivatedRoute, private app: ApplicationState,
        private router: Router, private platformAuth: PlatformAuthService, private snackbar: SnackbarService,
        private location: NgLocation, private userApi: UserApi, private formBuilder: FormBuilder,
        private analytics: AnalyticsService, private sanity: SanityService,
    ) {
        this.pageSlug$ = this.route.url.pipe(map((url: UrlSegment[]) => url[0].path)) as typeof this.pageSlug$;
        combineLatest([this.pageSlug$, this.route.queryParamMap]).subscribe(([slug, params]) => {
            const [returnToUrl, logoutReason, email] = [params.get(RETURN_TO), params.get(LOGOUT_REASON), params.get(EMAIL)];
            if (returnToUrl) {
                this.redirectTo = this.router.parseUrl(returnToUrl);
            }
            if (logoutReason === "invalid_token") {
                this.snackbar.warn(`The previously logged-in user session is no longer valid; please sign in again to continue.`);
                const newQueryParams: Params = {};
                if (params.has(RETURN_TO)) newQueryParams[RETURN_TO] = params.get(RETURN_TO);
                const url = this.router.createUrlTree([], { relativeTo: this.route, queryParams: newQueryParams }).toString();
                this.location.go(url);
            }
            if (email) {
                if (slug === "sign-in") this.signInForm.patchValue({ email });
                else if (slug === "sign-up") this.signUpForm.patchValue({ email });
            }
        });
    }

    microsoftSignIn() {
        this.processSignIn(this.identityAuth.microsoftLogin());
    }

    googleSignIn() {
        this.processSignIn(this.identityAuth.googleLogin());
    }

    githubSignIn() {
        this.processSignIn(this.identityAuth.githubLogin());
    }

    onSubmitSignInForm() {
        this.processSignIn(this.identityAuth.signIn(this.signInForm.value.email!, this.signInForm.value.password!));
    }

    processSignIn(signInObservable: Observable<UserCredential | void>) {
        let isNewUser = false;
        signInObservable.pipe(
            switchMap((credential) => {
                if (this.isUserCredential(credential) && getAdditionalUserInfo(credential)?.isNewUser) {
                    isNewUser = true;
                    return this.userApi.signUp(credential.user.email!);
                } else return of(null);
            }),
            switchMap(() => this.identityAuth.getIdToken()),
            switchMap((token) => this.platformAuth.authUserToken(token)),
            switchMap((userAuth) => {
                if (isNewUser) {
                    return this.sendVerifyEmailIfUniverified(userAuth).pipe(map(() => userAuth))
                } else return of(userAuth)
            })
        ).subscribe({
            next: (userAuth) => {
                if (this.pendingAccountLink?.email && this.pendingAccountLink?.email == userAuth.user?.email) {
                    this.processSignIn(this.identityAuth.credentialLink(this.pendingAccountLink!.credential).pipe(
                        tap(() => {
                            this.snackbar.info("Accounts linked");
                            this.pendingAccountLink = undefined;
                        })),
                    );
                } else {
                    this.app.userAuth$.next(userAuth);
                    if (this.redirectTo) this.router.navigateByUrl(this.redirectTo);
                    else this.router.navigate(["/"]);
                }
            },
            error: (err) => {
                if (err?.code == "auth/account-exists-with-different-credential") {
                    const email = err.customData.email as string;
                    this.pendingAccountLink = {
                        email,
                        credential: OAuthProvider.credentialFromError(err)!
                    };
                    this.platformAuth.getProviderForEmail(email).subscribe((provider) => {
                        const providerInfo = this.identityAuth.providerInfoByID(provider)
                        if (providerInfo.name != null) {
                            this.snackbar.infoPersistent(
                                `A user with the email ${err.customData.email} already exists. `
                                + `Please sign in with ${providerInfo.name} to link your accounts.`
                            );
                        } else {
                            this.snackbar.infoPersistent(
                                `A user with the email ${err.customData.email} already exists. `
                                + "Please sign in to link your accounts."
                            );
                        }
                        if (providerInfo.isEmail) this.signInForm.patchValue({ email });
                    })
                } else if (err?.code == "auth/email-already-in-use")
                    this.snackbar.errorPersistent("A user with the given email already exists");
                else if (["auth/user-not-found", "auth/wrong-password"].includes(err?.code)) this.snackbar.errorPersistent(
                    "No user exists with the provided email address and password"
                );
                else if (err?.code === "auth/user-disabled") this.snackbar.errorPersistent(
                    "This account has been disabled. If you didn't request this, please contact support at support@typedb.com"
                );
                else if (err?.code !== "auth/popup-closed-by-user") this.snackbar.errorPersistent(err?.message || err?.toString() || AUTHENTICATION_FAILED);
                this.isSubmitting$.next(false);
            }
        });
    }

    private isUserCredential(credential: UserCredential | void): credential is UserCredential {
        return !!credential;
    }

    onSubmitSignUpForm() {
        const [email, password] = [this.signUpForm.value.email!, this.signUpForm.value.password!];
        this.identityAuth.signUp(email, password).pipe(
            switchMap(() => this.userApi.signUp(email)),
            switchMap(() => this.identityAuth.getIdToken()),
            switchMap((token) => this.platformAuth.authUserToken(token)),
            tap((userAuth) => this.app.userAuth$.next(userAuth)),
            switchMap((userAuth) => this.sendVerifyEmailIfUniverified(userAuth))
        ).subscribe({
            next: () => {
                this.router.navigate(["/"]);
                this.analytics.google.reportAdConversion("signup");
            },
            error: (err) => {
                if (err?.code == "auth/email-already-in-use") {
                    this.snackbar.errorPersistent("A user with the given email already exists");
                }
                else this.snackbar.errorPersistent(err.toString() || AUTHENTICATION_FAILED);
                this.isSubmitting$.next(false);
            }
        });
    }

    sendVerifyEmailIfUniverified(userAuth: UserAuth) {
        if (userAuth.status === "unverified") return this.userApi.sendVerifyEmail(true);
        else if (userAuth.status === "verified") return of(ok);
        else {
            console.warn(`Expected user with status 'unverified', but was:`, userAuth);
            throw AUTHENTICATION_FAILED;
        }
    }
}
