/*
 * This unpublished material is proprietary to Vaticle.
 * All rights reserved. The methods and
 * techniques described herein are considered trade secrets
 * and/or confidential. Reproduction or distribution, in whole
 * or in part, is forbidden except by express written permission
 * of Vaticle.
 */

import { AsyncPipe } from "@angular/common";
import { Component, Input } from "@angular/core";
import { MatTableModule } from "@angular/material/table";
import { Project, ProjectColumn } from "../../../concept/project";
import { MatSortModule } from "@angular/material/sort";
import { ResourceTable, ResourceTableService } from "../../../service/resource-table.service";
import { DatetimePipe, MenuItem, SpinnerComponent } from "typedb-platform-framework";
import { TableSelectionCellComponent, TableSelectionHeaderCellComponent } from "../../../framework/table";
import { AccessLevelPipe } from "../../../framework/access-level.pipe";
import { AccessLevel } from "../../../concept/iam";
import { MatTooltipModule } from "@angular/material/tooltip";
import { MatMenuModule } from "@angular/material/menu";
import { MatButtonModule } from "@angular/material/button";

@Component({
    selector: "tp-projects-table",
    templateUrl: "./projects-table.component.html",
    standalone: true,
    imports: [
        AsyncPipe, DatetimePipe, MatTableModule, MatSortModule, MatTooltipModule, MatMenuModule, MatButtonModule,
        TableSelectionHeaderCellComponent, TableSelectionCellComponent, SpinnerComponent, AccessLevelPipe,
    ],
})
export class ProjectsTableComponent<ENTITY extends Project> {
    @Input({ required: true}) table!: ResourceTable<ENTITY, ProjectColumn>;
    @Input() rowPopupMenuItems?: ((row: Project, access: AccessLevel) => MenuItem[]) | null;

    constructor(public tableService: ResourceTableService) {}
}
