<tp-page-scaffold [pageAvailability]="availability$ | async">
  @if (orgHasCluster$ | async) {
    <div class="action-bar">
      <a routerLink="deploy" [relativeTo]="route">
        <tp-button
          [matTooltip]="(cannotDeployReason$ | async) || ''" buttonStyle="secondary"
          [enabled]="(deployEnabled$ | async) || false" buttonId="deployCluster"
        >
          <i class="fa-light fa-square-plus"></i> Create
        </tp-button>
      </a>
      <tp-button
        [matTooltip]="(cannotSuspendReason$ | async) || ''" buttonStyle="secondary" buttonId="suspendCluster"
        [enabled]="(suspendEnabled$ | async) || false" (click)="openSuspendResumeModalForSelection('suspend')"
      >
        <i class="fa-light fa-circle-pause"></i> Suspend
      </tp-button>
      <tp-button
        [matTooltip]="(cannotResumeReason$ | async) || ''" buttonStyle="secondary" buttonId="resumeCluster"
        [enabled]="(resumeEnabled$ | async) || false" (click)="openSuspendResumeModalForSelection('resume')"
      >
        <i class="fa-light fa-circle-play"></i> Resume
      </tp-button>
    </div>
    <article>
      <section>
        <tp-table-toolbar [table]="clusters" filterGroupId="clusters" tableTitle="Clusters"/>
        <tp-clusters-table [table]="clusters" [rowPopupMenuItems]="clusterMenuItems"/>
        <tp-table-paginator [table]="clusters"/>
      </section>
    </article>
  } @else {
    <a routerLink="deploy" [relativeTo]="route" class="no-clusters-deploy-cluster">
      <button
        [matTooltip]="(cannotDeployReason$ | async) || ''"
        [disabled]="!(deployEnabled$ | async)" buttonId="deployCluster"
      >
        <div class="no-clusters-button-content">
          <div class="no-clusters-logo">
            <img src="/assets/logo/cluster-deploy-128x132.svg" alt=""/>
          </div>
          <h2 class="no-clusters-title">Deploy a cluster</h2>
          <p>Deploy your first cluster and start developing on TypeDB instantly</p>
        </div>
      </button>
    </a>
  }
</tp-page-scaffold>
