/*
 * This unpublished material is proprietary to Vaticle.
 * All rights reserved. The methods and
 * techniques described herein are considered trade secrets
 * and/or confidential. Reproduction or distribution, in whole
 * or in part, is forbidden except by express written permission
 * of Vaticle.
 */

import { ClusterCtlProtoGetClusterOptionsRes } from "../../application/protocol/cluster-controller";
import {
    ClusterPresetProto, ClusterPresetProtoClusterPresetSpecProto,
    MachineTypeProto, MachineTypeProtoPaymentOptions,
    ProviderProto,
    RegionProto,
    StorageTypeProto,
    TypeDBVersionProto
} from "../../protocol/concept";

export interface ClusterOptions {
    providers: ProviderOptions[];
    typeDBVersions: TypeDBVersion[];
}

export interface Provider {
    id: string;
    name: string;
}

export interface ProviderOptions extends Provider {
    regions: RegionOptions[];
    isFreeAvailable: boolean;
}

export function getPaymentOptions(
    clusterOptions: ProviderOptions[], providerId: string,
    regionId: string | undefined, machineTypeVendorId: string | undefined
): PaymentOptions[] {
    const providerOptions = clusterOptions.find(x => x.id == providerId);
    if (!providerOptions) return [];
    if (!regionId) return [...new Set(providerOptions.regions.flatMap(region => region.machineTypes.flatMap(machineType => machineType.payableWith)))];
    const regionOptions = providerOptions.regions.find(x => x.id == regionId);
    if (!regionOptions) return [];
    if (!machineTypeVendorId) return [...new Set(regionOptions.machineTypes.flatMap(machineType => machineType.payableWith))];
    const machineType = regionOptions.machineTypes.find(x => x.vendorId == machineTypeVendorId);
    if (!machineType) return [];
    return machineType.payableWith;
}

export interface Region {
    id: string;
    continentName: string;
    continentOrdinal: number;
    countryCode: string;
    locationName: string;
    isFreeAvailable: boolean;
    vendorId: string;
}

export function regionToString(region: Region, form: "short" | "long" = "long"): string {
    return form === "long" ? `${region.locationName} (${region.vendorId})` : region.vendorId;
}

export interface RegionOptions extends Region {
    machineTypes: MachineType[];
    storageTypes: StorageType[];
    clusterPresets: ClusterPreset[];
}

export interface MachineType {
    vendorId: string;
    cpu: number;
    memGB: number;
    isFree: boolean;
    payableWith: PaymentOptions[];
}

export function machineTypeComputeString(machine: MachineType): string {
    return `${machine.cpu} vCPU, ${machine.memGB} GB RAM`;
}

export function machineTypeToString(machine: MachineType): string {
    return `${machineTypeComputeString(machine)} (${machine.vendorId})`;
}

export interface StorageType {
    vendorId: string;
    gbMin: number;
    gbMax: number;
    iops: string;
}

export function storageTypeToString(storage: StorageType): string {
    return `${storage.iops} (${storage.vendorId})`;
}

export interface TypeDBVersion {
    version: string;
    sampleDatasets: string[];
    singleNodeOnly: boolean;
}

export interface ClusterPreset {
    id: string;
    name: string;
    singleNodeSpec: ClusterPresetSpec;
    multiNodeSpec: ClusterPresetSpec;
}

export interface ClusterPresetSpec {
    machineType: MachineType;
    storageType: StorageType;
    storageGB: number;
    serverCount: number;
}

export enum PaymentOptions {
    CARD, MARKETPLACE, CREDITS
}

export function clusterOptionsOf(options: ClusterCtlProtoGetClusterOptionsRes): ClusterOptions {
    return {
        providers: [options.gcp, options.aws, options.azure].filter(x => !!x).map(providerOptionsOf),
        typeDBVersions: options.typedbVersions.map(typeDBVersionOf),
    };
}

export function providerOf(provider: ProviderProto): Provider {
    return {
        id: provider.id,
        name: provider.name,
    };
}

export function providerProtoOf(provider: Provider): ProviderProto {
    return new ProviderProto({
        id: provider.id,
        name: provider.name,
    });
}

export function providerOptionsOf(provider: ProviderProto): ProviderOptions {
    const regions = provider.regions.map((region) => regionOptionsOf(region))
    return {
        id: provider.id,
        name: provider.name,
        isFreeAvailable: regions.some(region => region.isFreeAvailable),
        regions,
    };
}

export function regionOf(region: RegionProto): Region {
    return {
        id: region.id,
        continentName: region.continentName,
        continentOrdinal: region.continentOrdinal,
        countryCode: region.countryCode,
        locationName: region.locationName,
        isFreeAvailable: region.machineTypes.some(machine => machine.isFree),
        vendorId: region.vendorId,
    };
}

export function regionProtoOf(region: Region): RegionProto {
    return new RegionProto({
        id: region.id,
        countryCode: region.countryCode,
        locationName: region.locationName,
        vendorId: region.vendorId
    });
}

function regionOptionsOf(region: RegionProto): RegionOptions {
    return {
        id: region.id,
        continentName: region.continentName,
        continentOrdinal: region.continentOrdinal,
        countryCode: region.countryCode,
        locationName: region.locationName,
        isFreeAvailable: region.machineTypes.some(machine => machine.isFree),
        vendorId: region.vendorId,
        machineTypes: region.machineTypes.map(machineTypeOf),
        storageTypes: region.storageTypes.map(storageTypeOf),
        clusterPresets: region.clusterPresets.map(clusterPresetOf),
    }
}

export function machineTypeOf(machineType: MachineTypeProto): MachineType {
    return {
        vendorId: machineType.vendorId,
        cpu: machineType.cpu,
        memGB: machineType.ramGb,
        isFree: machineType.isFree,
        payableWith: machineType.payableWith.map(paymentOptionOf),
    }
}

export function machineTypeProtoOf(machineType: MachineType): MachineTypeProto {
    return new MachineTypeProto({ vendorId: machineType.vendorId, isFree: machineType.isFree });
}

export function storageTypeOf(storageType: StorageTypeProto): StorageType {
    return {
        vendorId: storageType.vendorId,
        gbMin: storageType.minGb,
        gbMax: storageType.maxGb,
        iops: storageType.iops,
    };
}

export function storageTypeProtoOf(storageType: StorageType): StorageTypeProto {
    return new StorageTypeProto({ vendorId: storageType.vendorId });
}

export function typeDBVersionOf(typeDBVersion: TypeDBVersionProto): TypeDBVersion {
    return {
        version: typeDBVersion.version,
        sampleDatasets: typeDBVersion.sampleDatasets,
        singleNodeOnly: typeDBVersion.singleNodeOnly,
    };
}

export function typeDBVersionProtoOf(typeDBVersion: TypeDBVersion): TypeDBVersionProto {
    return new TypeDBVersionProto({
        version: typeDBVersion.version,
        sampleDatasets: typeDBVersion.sampleDatasets
    });
}

export function clusterPresetOf(data: ClusterPresetProto): ClusterPreset {
    return {
        id: data.id,
        name: data.name,
        singleNodeSpec: clusterPresetSpecOf(data.singleNodeSpec),
        multiNodeSpec: clusterPresetSpecOf(data.multiNodeSpec),
    };
}

function clusterPresetSpecOf(data: ClusterPresetProtoClusterPresetSpecProto): ClusterPresetSpec {
    return {
        machineType: machineTypeOf(data.machineType),
        storageType: storageTypeOf(data.storageType),
        storageGB: data.storageGb,
        serverCount: data.serverCount,
    };
}

export function paymentOptionOf(data: MachineTypeProtoPaymentOptions): PaymentOptions {
    switch (data) {
        case MachineTypeProtoPaymentOptions.CARD: return PaymentOptions.CARD
        case MachineTypeProtoPaymentOptions.MARKETPLACE: return PaymentOptions.MARKETPLACE
        case MachineTypeProtoPaymentOptions.CREDITS: return PaymentOptions.CREDITS
    }
}
