<mat-form-field class="tp-org-selector">
  <mat-select [value]="org" (selectionChange)="onOrgChange($event)">
    @for (org of app.currentUserOrgs$ | async; track org) {
      <mat-option [value]="org">{{ org.id }}</mat-option>
    }
    <hr/>
    <button type="button" class="selector-button mat-mdc-option mdc-list-item" (click)="onCreateOrgClick()">
      <i class="fa-light fa-plus"></i><span>Create organization</span>
    </button>
  </mat-select>
</mat-form-field>

<div class="links">
  <tp-sidebar-link [link]="'/org/' + org.id + '/clusters'" domain="clusters" idSuffix="clusters"><i class="fa-light fa-objects-column"></i><span>Clusters</span></tp-sidebar-link>
  <tp-sidebar-link [link]="'/org/' + org.id + '/projects'" domain="projects" idSuffix="projects"><i class="fa-light fa-folders"></i><span>Projects</span></tp-sidebar-link>
  <tp-sidebar-link [link]="'/org/' + org.id + '/teams'" domain="teams" idSuffix="teams"><i class="fa-light fa-users"></i><span>Teams</span></tp-sidebar-link>
  <tp-sidebar-link [link]="'/org/' + org.id + '/members'" domain="members" idSuffix="members"><i class="fa-light fa-user-group"></i><span>Members</span></tp-sidebar-link>
  @if (app.hasOrgAdminAccess$ | async) {
    <tp-sidebar-link [link]="'/org/' + org.id + '/settings'" domain="settings" idSuffix="settings"><i class="fa-light fa-gear"></i><span>Settings</span></tp-sidebar-link>
  }
  <div class="flex-spacer"></div>
  <tp-sidebar-link idSuffix="support"><i class="fa-brands fa-intercom"></i>Support</tp-sidebar-link>
</div>
