/*
 * This unpublished material is proprietary to Vaticle.
 * All rights reserved. The methods and
 * techniques described herein are considered trade secrets
 * and/or confidential. Reproduction or distribution, in whole
 * or in part, is forbidden except by express written permission
 * of Vaticle.
 */

import { Component, OnDestroy } from "@angular/core";
import { ActivatedRoute, Router, RouterLink } from "@angular/router";
import { Subject, combineLatest, map, startWith, switchMap } from "rxjs";
import {
    ConfirmationModalComponent, ConfirmationModalData, StrongConfirmationModalComponent, StrongConfirmationModalData,
    ButtonComponent,
    MenuItem,
} from "typedb-platform-framework";
import {
    Cluster, clusterProperties, isInConnectableState,
    isInDestroyableState,
    isInResumableState,
    isInSuspendableState
} from "../../../concept/cluster";
import { LOADING, NO_ITEMS_SELECTED } from "../../../framework/strings";
import { CONNECT, DIALOG } from "../../../framework/url-params";
import { ClusterApi } from "../../../service/cluster/cluster-api.service";
import { confirmationMessage, strongConfirmationStringOf } from "../../../service/dialog.service";
import { OrgController } from "../../../service/org/org-controller.service";
import { SnackbarService } from "../../../service/snackbar.service";
import { AsyncPipe } from "@angular/common";
import { resourceAvailabilityStreamOf, PageScaffoldComponent } from "../../scaffold/page/page-scaffold.component";
import { ApplicationState } from "../../../service/application-state.service";
import { ClustersTableComponent } from "../table/clusters-table.component";
import { MatDialog } from "@angular/material/dialog";
import { TablePaginatorComponent, TableToolbarComponent } from "../../../framework/table";
import { MatTooltipModule } from "@angular/material/tooltip";
import { AccessLevel, hasAdminAccess, hasReadAccess, hasWriteAccess } from "../../../concept/iam";
import { projectProperties } from "../../../concept/project";
import { clusterDetailsPath } from "../../../routing/resource-paths";
import { SuspendResumeModalComponent, SuspendResumeModalData } from "../suspend-resume/suspend-resume-modal.component";

@Component({
    selector: "tp-clusters-page",
    templateUrl: "./clusters-page.component.html",
    styleUrls: ["./clusters-page.component.scss"],
    standalone: true,
    imports: [
        PageScaffoldComponent, AsyncPipe, ConfirmationModalComponent, RouterLink, TableToolbarComponent,
        ClustersTableComponent, TablePaginatorComponent, ButtonComponent, MatTooltipModule,
    ],
})
export class ClustersPageComponent implements OnDestroy {
    readonly org = this.app.requireCurrentOrg();

    private readonly unsub$ = new Subject<void>();
    readonly clusters = this.orgCtl.clustersTable(this.unsub$);
    readonly availability$ = resourceAvailabilityStreamOf(this.clusters);

    readonly writableProjects$ = this.orgCtl.listWritableProjectsSnapshot();
    readonly cannotDeployReason$ = this.writableProjects$.pipe(map(writableProjects => {
        if (!writableProjects.length) return "You need write access to at least one project to deploy a new cluster";
        return null;
    }), startWith(LOADING));
    readonly cannotSuspendReason$ = this.clusters.hasWriteAccessToSelected$.pipe(map(canWrite => {
        if (!this.clusters.isAnySelected()) return NO_ITEMS_SELECTED;
        if (!canWrite) return "You don't have write access to one or more of the selected cluster(s)";
        if (this.clusters.selected.some(x => !isInSuspendableState(x))) return "A selected cluster is not running";
        return null;
    }), startWith(LOADING));
    readonly cannotResumeReason$ = this.clusters.hasWriteAccessToSelected$.pipe(map(canWrite => {
        if (!this.clusters.isAnySelected()) return NO_ITEMS_SELECTED;
        if (!canWrite) return "You don't have write access to one or more of the selected cluster(s)";
        if (this.clusters.selected.some(x => !isInResumableState(x))) return "A selected cluster is not suspended";
        return null;
    }), startWith(LOADING));
    readonly deployEnabled$ = this.cannotDeployReason$.pipe(map(x => !x));
    readonly suspendEnabled$ = this.cannotSuspendReason$.pipe(map(x => !x));
    readonly resumeEnabled$ = this.cannotResumeReason$.pipe(map(x => !x));
    readonly orgHasCluster$ = this.orgCtl.clustersTable(this.unsub$).items$.pipe(map(x => x.length > 0));

    readonly clusterMenuItems: (cluster: Cluster, accessLevel: AccessLevel) => MenuItem[] = (cluster, accessLevel) => [
        {
            label: "Connect",
            action: () => this.router.navigate([clusterDetailsPath(cluster, this.org)], { queryParams: { [DIALOG]: CONNECT } }),
            disabled: !hasReadAccess(accessLevel) || !isInConnectableState(cluster),
            disabledReason: !hasReadAccess(accessLevel) ? "You don't have read access to this cluster" :
                (!isInConnectableState(cluster) ? "This cluster is not running": undefined),
        },
        {
            label: "Suspend",
            action: () => this.openSuspendResumeModalSingle("suspend", cluster),
            disabled: !hasWriteAccess(accessLevel) || !isInSuspendableState(cluster),
            disabledReason: !hasWriteAccess(accessLevel) ? "You don't have write access to this cluster" :
                (!isInSuspendableState(cluster) ? "This cluster is not running" : undefined)
        },
        {
            label: "Resume",
            action: () => this.openSuspendResumeModalSingle("resume", cluster),
            disabled: !hasWriteAccess(accessLevel) || !isInResumableState(cluster),
            disabledReason: !hasWriteAccess(accessLevel) ? "You don't have write access to this cluster" :
                (!isInResumableState(cluster) ? "This cluster is not suspended" : undefined)
        },
        {
            label: "Destroy",
            action: () => this.openDestroyModal(cluster),
            disabled: !hasAdminAccess(accessLevel) || !isInDestroyableState(cluster),
            disabledReason: !hasWriteAccess(accessLevel) ? "You don't have admin access to this cluster" :
                (!isInDestroyableState(cluster) ? "This cluster is not in a destroyable state" : undefined)
        },
    ];

    constructor(
        private clusterApi: ClusterApi, public route: ActivatedRoute, private snackbar: SnackbarService,
        private app: ApplicationState, private orgCtl: OrgController, private dialog: MatDialog,
        private router: Router,
    ) {}

    ngOnDestroy() {
        this.unsub$.next();
    }

    openSuspendResumeModalSingle(action: "suspend" | "resume", cluster: Cluster) {
        const modal = this.dialog.open<SuspendResumeModalComponent, SuspendResumeModalData>(SuspendResumeModalComponent, {
            data: { cluster, action }
        }).componentInstance;
        modal.confirmed.pipe(
            switchMap(() => {
                switch (action) {
                    case "suspend": return this.clusterApi.suspendCluster(cluster.uuid);
                    case "resume": return this.clusterApi.resumeCluster(cluster.uuid);
                }
            }),
        ).subscribe({
            next: () => {
                modal.close();
                switch (action) {
                    case "suspend": this.snackbar.success(`'${cluster.id}' is now being suspended.`); break;
                    case "resume": this.snackbar.success(`'${cluster.id}' is now being resumed.`); break;
                }
            },
            error: () => {
                modal.isSubmitting$.next(false);
            },
        });
    }

    openSuspendResumeModalForSelection(action: "suspend" | "resume") {
        const clusters = [...this.clusters.selected];
        const title = action === "resume"
            ? clusters.length === 1 ? "Resume cluster" : "Resume clusters"
            : clusters.length === 1 ? "Suspend cluster" : "Suspend clusters";
        const modal = this.dialog.open<ConfirmationModalComponent, ConfirmationModalData>(ConfirmationModalComponent, {
            data: {
                title: title,
                body: confirmationMessage(action, clusters, "cluster"),
                confirmText: title,
                confirmButtonStyle: action === "resume" ? "primary-solid green" : "primary-solid red",
            },
        }).componentInstance;
        modal.confirmed.pipe(
            switchMap(() => {
                switch (action) {
                    case "suspend": return combineLatest(clusters.map(x => this.clusterApi.suspendCluster(x.uuid)));
                    case "resume": return combineLatest(clusters.map(x => this.clusterApi.resumeCluster(x.uuid)));
                }
            }),
        ).subscribe({
            next: () => {
                modal.close();
                switch (action) {
                    case "suspend": this.snackbar.success(`Cluster(s) now being suspended.`); break;
                    case "resume": this.snackbar.success(`Cluster(s) now being resumed.`); break;
                }
            },
            error: () => {
                modal.isSubmitting$.next(false);
            },
        });
    }

    openDestroyModal(cluster: Cluster) {
        const modal = this.dialog.open<StrongConfirmationModalComponent, StrongConfirmationModalData>(StrongConfirmationModalComponent, {
            data: {
                title: "Destroy cluster",
                body: confirmationMessage("delete", [cluster], "cluster") + ` All of its data will be permanently erased. This action cannot be undone!`,
                confirmText: "Destroy cluster",
                strongConfirmationString: strongConfirmationStringOf([cluster]),
            },
        }).componentInstance;
        modal.confirmed.pipe(switchMap(() => this.clusterApi.destroyCluster(cluster.uuid))).subscribe({
            next: () => {
                modal.close();
                this.snackbar.success(`Cluster now being destroyed.`);
            },
            error: () => {
                modal.isSubmitting$.next(false);
            },
        });
    }
}
