<tp-modal [dialogTitle]="title" [isBusy]="isSubmitting$ | async">
  @if (price$ | async; as price) {
    <p class="content-wrapper">
      Are you sure you would like to {{ data.action }} '{{ data.cluster.id }}'?
    </p>

    @if (price.running.centsPerHour != 0) {
      @if (data.action == "suspend") {
        <p class="content-wrapper">
          Doing so will prevent you from reading or writing data until it's resumed.
          We will continue to maintain your data during suspension until you choose to resume or destroy the cluster.
        </p>
        <div class="content-wrapper">
          <i class="fa-solid fa-circle-info"></i>
          <p>
            Your running cluster costs <strong>{{ runningPriceString(price) }}</strong>. When suspended, it will cost <strong>{{ suspendedPriceString(price) }}</strong>
          </p>
        </div>
      } @else {
        <div class="content-wrapper">
          <i class="fa-solid fa-circle-info"></i>
          <p>
            Your suspended cluster costs <strong>{{ suspendedPriceString(price) }}</strong>. When running, it will cost <strong>{{ runningPriceString(price) }}</strong>
          </p>
        </div>
      }
    }
  } @else {
    <tp-spinner/>
  }
  <tp-form-actions
    [submitText]="submitText" [submitButtonStyle]="submitButtonStyle"
    [buttonIdPrefix]="buttonIdPrefix" [submitDisabled]="!(price$ | async)"
    [isSubmitting]="isSubmitting$ | async" (submitClick)="confirm()" [cancellable]="true" (cancel)="close()"
  />
</tp-modal>
