@if (tagline$ | async; as tagline) {
  <tp-p-with-highlights class="tp-setup" level="p2" [value]="tagline"/>
}

@if (survey) {
  <mat-accordion>
    @for (section of survey.sections; track section; let sectionIndex = $index) {
      <mat-expansion-panel [expanded]="$first">
        <mat-expansion-panel-header>
          <mat-panel-title>
            @if (section.isCompleted) {
              <i class="fa-light fa-check ok"></i>
            } @else {
              <i class="fa-light fa-hyphen"></i>
            }
            {{ section.spec.name || "" }}
          </mat-panel-title>
        </mat-expansion-panel-header>

        @for (question of section.questions; track question) {
          @if (isMultipleChoiceQuestion(question)) {
            <tp-multiple-choice-question [question]="question" (answerChanged)="onQuestionAnswerChanged(section, question)"/>
          } @else if (isCloudRegionQuestion(question)) {
            <tp-cloud-region-question
              [question]="question" [optionGroups]="cloudRegionOptionGroups" [isFreeText]="regionQuestionIsFreeText"
              (answerChanged)="onQuestionAnswerChanged(section, question)"
            />
          }
        }

        @if (!$last) {
          <div class="tp-advance">
            <p><a (click)="expandSection(sectionIndex + 1)">
              Next: {{ survey.sections[sectionIndex + 1].spec.name }} →
            </a></p>
          </div>
        }
      </mat-expansion-panel>
    }
  </mat-accordion>

  <div class="tp-finish">
    <tp-form-actions
      [submitDisabled]="!survey.isCompleted" submitText="Continue" [isSubmitting]="isSubmitting" buttonIdPrefix="completeSurvey"
      [matTooltip]="finishButtonTooltip" submitButtonStyle="primary-solid green" (submitClick)="completeSurvey()"
    />
  </div>
} @else {
  <tp-spinner/>
}
