/*
 * This unpublished material is proprietary to Vaticle.
 * All rights reserved. The methods and
 * techniques described herein are considered trade secrets
 * and/or confidential. Reproduction or distribution, in whole
 * or in part, is forbidden except by express written permission
 * of Vaticle.
 */

import { AsyncPipe } from "@angular/common";
import { Component, Input } from "@angular/core";
import { MatTableModule } from "@angular/material/table";
import { Cluster, ClusterColumn } from "../../../concept/cluster";
import { MatSortModule } from "@angular/material/sort";
import { ResourceTable, ResourceTableService } from "../../../service/resource-table.service";
import { ClusterStatusPipe } from "../cluster-status.pipe";
import { RegionPipe } from "../region.pipe";
import { DatetimePipe, MenuItem, SpinnerComponent } from "typedb-platform-framework";
import { StatusIcon, TableSelectionCellComponent, TableSelectionHeaderCellComponent, TableStatusCellComponent } from "../../../framework/table";
import { Router } from "@angular/router";
import { projectDetailsPath } from "../../../routing/resource-paths";
import { PartialWithId } from "../../../concept/base";
import { Project } from "../../../concept/project";
import { ApplicationState } from "../../../service/application-state.service";
import { AccessLevel } from "../../../concept/iam";
import { MatMenuModule } from "@angular/material/menu";
import { MatTooltipModule } from "@angular/material/tooltip";
import { MatButtonModule } from "@angular/material/button";

@Component({
    selector: "tp-clusters-table",
    templateUrl: "./clusters-table.component.html",
    standalone: true,
    imports: [
        AsyncPipe, DatetimePipe, MatTableModule, MatSortModule, MatMenuModule, MatTooltipModule, MatButtonModule,
        TableSelectionHeaderCellComponent, TableSelectionCellComponent, RegionPipe, ClusterStatusPipe, TableStatusCellComponent, SpinnerComponent,
    ],
})
export class ClustersTableComponent {
    @Input({ required: true }) table!: ResourceTable<Cluster, ClusterColumn>;
    @Input() rowPopupMenuItems?: ((row: Cluster, access: AccessLevel) => MenuItem[]) | null;

    constructor(public tableService: ResourceTableService, private router: Router, private app: ApplicationState) {}

    clusterIcon(cluster: Cluster): StatusIcon {
        switch (cluster.status) {
            case "running":
                return { color: "ok" };

            case "starting":
            case "resuming":
            case "suspending":
            case "destroying":
                return { loading: true, paused: false };

            case "suspended":
                return { color: "inactive" };
            case "destroyed":
                return { color: "inactive" };
            case "error":
                return { color: "error" };
        }
    }

    openProject(project: PartialWithId<Project>) {
        this.router.navigate([projectDetailsPath(project, this.app.requireCurrentOrg())]);
    }
}
