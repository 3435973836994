<tp-page-scaffold>
  <div class="action-bar">
    @if (breadcrumb$ | async; as breadcrumb) {
      <tp-breadcrumb [items]="breadcrumb.items" [url]="breadcrumb.url"/>
    }
  </div>
  @if (team$ | async; as team) {
    <article>
      <section>
        <div class="section-title-row">
          <h2>{{ team.name }} ({{ team.id }})</h2>
          <tp-button
            (click)="openEditModal()" buttonId="editTeam"
            buttonStyle="secondary stroke" [enabled]="(hasWriteAccess$ | async) || false"
          >
            <i class="fa-light fa-pen-to-square"></i> Edit
          </tp-button>
        </div>

        <tp-properties-table title="Team Details">
          <tp-properties-table-row key="Team ID">{{ team.id }}</tp-properties-table-row>
          <tp-properties-table-row key="Team Name">{{ team.name }}</tp-properties-table-row>
          <tp-properties-table-row key="Creation Date">{{ team.createdAt | datetime }}</tp-properties-table-row>
        </tp-properties-table>
      </section>

      @if (members) {
        <section>
          <tp-table-toolbar [table]="members" filterGroupId="members" tableTitle="Members" [actions]="[addUserButton]"/>
          <tp-users-table [table]="members" [rowPopupMenuItems]="membersTableRowPopupMenuItems"/>
          <tp-table-paginator [table]="members"/>
        </section>
      }

      @if (projects) {
        <section>
          <tp-table-toolbar [table]="projects" filterGroupId="projects" tableTitle="Projects"/>
          <tp-projects-table [table]="projects"/>
          <tp-table-paginator [table]="projects"/>
        </section>
      }

      <tp-delete-resource-section
        title="Delete this team"
        [display]="{ text: 'Delete the team from this organization' }"
        buttonText="Delete"
        buttonIconClass="fa-light fa-trash-can-xmark"
        (buttonPressed)="openDeleteModal()"
      />
    </article>
  }
</tp-page-scaffold>
