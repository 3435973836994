/*
 * This unpublished material is proprietary to Vaticle.
 * All rights reserved. The methods and
 * techniques described herein are considered trade secrets
 * and/or confidential. Reproduction or distribution, in whole
 * or in part, is forbidden except by express written permission
 * of Vaticle.
 */

import { AsyncPipe } from "@angular/common";
import { Component, OnDestroy, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { ActivatedRoute, Router } from "@angular/router";
import {
    BehaviorSubject,
    catchError,
    distinctUntilChanged,
    first,
    map,
    Observable,
    of,
    Subject,
    switchMap,
    tap
} from "rxjs";
import {
    BreadcrumbComponent,
    ButtonComponent,
    ConfirmationModalComponent,
    DatetimePipe,
    DeleteResourceSectionComponent,
    MenuItem,
    ModalComponent,
    PropertiesTableComponent,
    PropertiesTableRowComponent,
    StrongConfirmationModalComponent,
    StrongConfirmationModalData
} from "typedb-platform-framework";
import { Parameters } from "../../../concept/common";
import { AccessLevel, hasAdminAccess, hasWriteAccess } from "../../../concept/iam";
import { ProjectColumn, TeamProject } from "../../../concept/project";
import { Team } from "../../../concept/team";
import { TeamMember, User, UserColumn } from "../../../concept/user";
import { teamsPath } from "../../../routing/resource-paths";
import { DialogResult } from "../../../service/dialog.service";
import { OrgApi } from "../../../service/org/org-api.service";
import { ResourceTable } from "../../../service/resource-table.service";
import { ResourceService } from "../../../service/resource.service";
import { TeamApi } from "../../../service/team/team-api.service";
import { ApplicationState } from "../../../service/application-state.service";
import { SnackbarService } from "../../../service/snackbar.service";
import { TeamController } from "../../../service/team/team-controller.service";
import { ProjectsTableComponent } from "../../project/table/projects-table.component";
import { PageScaffoldComponent, ResourceAvailability } from "../../scaffold/page/page-scaffold.component";
import { TeamAddUserDialogComponent, TeamAddUserDialogData } from "../add-user/team-add-user-dialog.component";
import { TeamUpdateDialogComponent, TeamUpdateDialogData } from "../update/team-update-dialog.component";
import { TablePaginatorComponent, TableToolbarAction, TableToolbarComponent } from "../../../framework/table";
import { UsersTableComponent } from "../../member/table/users-table.component";
import { TeamSetUserAccessLevelDialogComponent } from "../set-user-access-level/team-set-user-access-level-dialog.component";

@Component({
    selector: "tp-team-details-page",
    templateUrl: "./team-details-page.component.html",
    standalone: true,
    imports: [
        PageScaffoldComponent, BreadcrumbComponent, ButtonComponent, PropertiesTableComponent, ModalComponent,
        DeleteResourceSectionComponent, TablePaginatorComponent, ProjectsTableComponent, ConfirmationModalComponent,
        AsyncPipe, TableToolbarComponent, PropertiesTableRowComponent, DatetimePipe,
        UsersTableComponent,
    ],
})
export class TeamDetailsPageComponent implements OnInit, OnDestroy {
    readonly team$ = new BehaviorSubject<Team | null>(null);
    readonly teamAccess$ = this.team$.pipe(switchMap(team => {
        if (team) return this.teamApi.getAccessLevels([team.uuid]).pipe(map(x => x[0]));
        else return of("none" as AccessLevel);
    }));
    readonly hasWriteAccess$ = this.teamAccess$.pipe(map(x => hasWriteAccess(x)))
    readonly hasAdminAccess$ = this.teamAccess$.pipe(map(x => hasAdminAccess(x)));
    readonly breadcrumb$ = this.team$.pipe(
        map((team) => {return !team ? null : { items: [team.id], url: teamsPath(this.app.requireCurrentOrg()) };}),
    );

    readonly addUserButton: TableToolbarAction = {
        text: "Add user",
        enabled: false,
        onClick: () => { this.showAddUserDialog(); }
    };
    private readonly unsub$ = new Subject<void>();
    readonly availability$: Observable<ResourceAvailability> = this.team$.pipe(map(x => x ? "ready" : "loading"), catchError(() => of("failed" as const)));

    private readonly unsubMembers$ = new Subject<void>();
    members?: ResourceTable<TeamMember, UserColumn>;
    private readonly unsubProjects$ = new Subject<void>();
    projects?: ResourceTable<TeamProject, ProjectColumn>;

    membersTableRowPopupMenuItems: (user: TeamMember) => MenuItem[] = () => [];

    constructor(
        private app: ApplicationState, private router: Router, private route: ActivatedRoute,
        private teamApi: TeamApi, private teamCtl: TeamController, private orgApi: OrgApi,
        private snackbar: SnackbarService, private dialog: MatDialog, private resourceService: ResourceService,
    ) {}

    ngOnInit() {
        this.initDataLoader();
    }

    ngOnDestroy() {
        this.unsub$.next();
        this.unsubMembers$.next();
        this.unsubProjects$.next();
    }

    initDataLoader() {
        this.team$.pipe(first(x => !!x), map(x => x!)).subscribe(team => {
            this.members = this.teamCtl.membersTable(team, this.unsubMembers$);
            this.projects = this.teamCtl.projectsTable(team, this.unsubProjects$);
            this.teamAccess$.subscribe(accessLevel => {
                this.membersTableRowPopupMenuItems = (user: TeamMember): MenuItem[] => {
                    return hasAdminAccess(accessLevel) ? [
                        {
                            label: "Change access level",
                            action: () => {
                                this.dialog.open(TeamSetUserAccessLevelDialogComponent, { data: { team, user } });
                            }
                        },
                        {
                            label: "Remove from team",
                            action: () => {
                                this.teamApi.removeMember({ teamUuid: team.uuid, userUuid: user.uuid }).subscribe(() => this.members!.refresh());
                            }
                        },
                    ] : [];
                }
            });
        });

        this.route.params.pipe(
            map((params) => params["team-id"] as string),
            distinctUntilChanged(),
            tap(() => { this.unsub$.next(); }),
            switchMap((teamId) => this.teamApi.getTeam({ teamId, orgUuid: this.app.requireCurrentOrg().uuid }, of(undefined))),
        ).subscribe({
            next: (res) => {
                this.resourceService.processResponse({ resource$: this.team$, res: res, unsub$: this.unsub$, onResync: () => this.initDataLoader() });
            },
            error: (err) => {
                this.team$.error(err);
            }
        });

        this.hasAdminAccess$.subscribe(canAdmin => {
            this.addUserButton.enabled = canAdmin;
        });
    }

    openEditModal() {
        this.dialog.open<TeamUpdateDialogComponent, TeamUpdateDialogData, DialogResult>(TeamUpdateDialogComponent, {
            data: { team: this.team$.value! }
        }).beforeClosed().subscribe((result) => {
            if (result === "ok") this.initDataLoader();
        });
    }

    openDeleteModal() {
        const team = this.team$.value!;
        const modal = this.dialog.open<StrongConfirmationModalComponent, StrongConfirmationModalData>(StrongConfirmationModalComponent, {
            data: {
                title: "Delete team",
                body: `Are you sure you would like to delete '${team.name}'? This action cannot be undone.`,
                confirmText: "Delete team",
                strongConfirmationString: team.id,
            },
        }).componentInstance;
        modal.confirmed.pipe(switchMap(() => this.teamApi.deleteTeam(team.uuid))).subscribe({
            next: () => {
                modal.close();
                this.snackbar.success(`Team '${team.id}' is now deleted.`);
                this.router.navigate(["../../teams"], { relativeTo: this.route });
            },
            error: () => {
                modal.isSubmitting$.next(false);
            },
        });
    }

    showAddUserDialog() {
        const params: Parameters = { pagination: { offset: 0, limit: 1000 }, sorting: { attributeType: "id" } }

        this.orgApi.listMembers(this.app.requireCurrentOrg().uuid, params, of(undefined)).pipe(
            switchMap((res) => {
                const orgMembers = res.initial!;
                return this.dialog.open<TeamAddUserDialogComponent, TeamAddUserDialogData, DialogResult>(
                    TeamAddUserDialogComponent, { data: { orgMembers, team: this.team$.value! } }
                ).beforeClosed();
            })
        ).subscribe((result) => {
            if (result === "ok") this.initDataLoader();
        });
    }
}
