<div class="form-container">
  <p class="title">Connect {{ providerName }} Billing Account</p>
  @if (orgOptions$ | async) {
    <tp-form [formGroup]="form" [isSubmitting$]="isSubmitting$" (doSubmit)="onSubmitConnectOrganizationForm()">
      <mat-form-field>
        <mat-label>Organization</mat-label>
        <mat-select [formControl]="form.controls.org">
          @for (org of orgOptions$ | async; track org) {
            <mat-option [disabled]="!org.enabled" [value]="org.value">{{ org.viewValue }}</mat-option>
          }
          <hr/>
          <button type="button" class="selector-button mat-mdc-option mdc-list-item" (click)="onCreateOrgClick()">
            <i class="fa-light fa-plus"></i><span>Create organization</span>
          </button>
        </mat-select>
      </mat-form-field>
      <tp-form-actions [isSubmitting]="isSubmitting$ | async" submitText="Connect" [buttonIdPrefix]="buttonIdPrefix"/>
    </tp-form>
  } @else {
    <tp-spinner/>
  }
</div>
