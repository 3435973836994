@if (table.ready$ | async) {
  <table mat-table [dataSource]="table.items$" matSort (matSortChange)="tableService.handleMatSortChange(table, $event)">
    <ng-container matColumnDef="select">
      <th mat-header-cell *matHeaderCellDef><tp-table-selection-header-cell [table]="table"/></th>
      <td mat-cell *matCellDef="let team"><tp-table-selection-cell [table]="table" [row]="team"/></td>
    </ng-container>
    <ng-container matColumnDef="id">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ table.columns.id.name }}</th>
      <td mat-cell *matCellDef="let team">{{ team.id }}</td>
    </ng-container>
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ table.columns.name.name }}</th>
      <td mat-cell *matCellDef="let team">{{ team.name }}</td>
    </ng-container>
    <ng-container matColumnDef="createdAt">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ table.columns.createdAt.name }}</th>
      <td mat-cell *matCellDef="let team">{{ team.createdAt | datetime }}</td>
    </ng-container>
    <ng-container matColumnDef="accessLevel">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ table.columns.accessLevel.name }}</th>
      <td mat-cell *matCellDef="let team">{{ team.accessLevel | accessLevel }}</td>
    </ng-container>
    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let team">
        <button
          mat-fab aria-label="more" [disabled]="!rowPopupMenuItems!(team, (table.accessLevelByUuid$(team.uuid) | async)!).length"
          [matMenuTriggerFor]="rowPopupMenu" [matMenuTriggerData]="{ items: rowPopupMenuItems!(team, (table.accessLevelByUuid$(team.uuid) | async)!) }"
          (click)="$event.stopPropagation()"
        >
          <i class="fa-light fa-ellipsis"></i>
        </button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="table.displayedColumns"></tr>
    <tr
      mat-row *matRowDef="let team; columns: table.displayedColumns"
      [class]="tableService.rowClass$(table, team) | async"
      (click)="tableService.handleRowClick(table, team)"
    ></tr>
  </table>
} @else {
  <tp-spinner/>
}

<mat-menu #rowPopupMenu="matMenu">
  <ng-template matMenuContent let-items="items">
    @for (item of items; track item) {
      <button mat-menu-item [matTooltip]="item.disabledReason" [disabled]="item.disabled" (click)="item.action()">{{ item.label }}</button>
    }
  </ng-template>
</mat-menu>
